

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import CommonMixin from "../../../../mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({})
export default class ManageProcessor extends mixins(CommonMixin) {
  public brokerStaff = [];
  public isChecked = true;
  public brokerStaffInfo: any = {};
  public roles = null;
  public validation = [];
  public errorMessage = "";
  public loader = true;
  public staff = [];
  public previousInProcess = false;
  public taskRole = [];
  /**
   * to update the processor activity
   */
  public async switchBalancing(i, processorId) {
    try {
      let sure = false;
      let msg = !this.brokerStaff[i].inLoadBalancing
        ? "Are you sure you want to stop sending new loan?"
        : "Are you sure you want to  send new loan?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.brokerStaff[i].inLoadBalancing = !this.brokerStaff[i]
            .inLoadBalancing;
          this.$snotify.error("No changes");
        });
      if (sure) {
        await this.updateProcessorAudiTrail({
          performedBy: this.$store.state.sessionObject.userId,
          activity: this.brokerStaff[i].inLoadBalancing,
          processorId: processorId
        });
        // let response = await Axios.post(
        //   BASE_API_URL + "wemloStaff/updateProcessorAudiTrail",
        //   {
        //     performedBy: this.$store.state.sessionObject.userId,
        //     activity: this.brokerStaff[i].inLoadBalancing,
        //     processorId: processorId
        //   },
        //   {
        //
        //   }
        // );
        // this.$snotify.success("Updated");
      }
    } catch (error) {
      console.log(error, "switchBalancing");
    }
  }

  /**
   * Calling from @change "On click of checkbox"
   */
  public changeValue(role, j, brokerStaff, i) {
    try {
      if (role.id == 7 && role.checked) {
        this.taskRole.forEach(t => t.checked = true);
        this.pushTask(i);
      } else if (role.id == 7 && !role.checked) {
        this.taskRole.forEach(t => t.checked = false);
        this.pushTask(i);
      } else {
        this.staff.push({
          taskId: this.brokerStaff[i].roles[j].id,
          processorId: this.brokerStaff[i].userId,
          checked: this.brokerStaff[i].roles[j].checked
        });
      }
    } catch (error) {
      console.log(error, "changeValue");
    }
  }

  public pushTask(i) {
    this.taskRole.forEach(e => {
      let obj = {
        taskId: e.id,
        processorId: this.brokerStaff[i].userId,
        checked: e.checked
      };
      this.staff.push(obj);
    });
  }
  /**
   * Saving processorId against role
   */
  public async saveProcessorAgainstRole() {
    try {
      if (this.brokerStaff.length > 0) {
        this.errorMessage = "";
        this.validate();
      }
      if (this.validation.length == 0) {
        let response = await Axios.post(
          BASE_API_URL + "broker/saveProcessorAgainstRole",
          {
            brokerStaff: this.staff,
            userId: this.$userId
          });
        this.staff = [];
        this.$snotify.success("Changes added successfully");
        await this.$store.dispatch(
          "updatePreRequisite",
          this.completedPreRequisite + 1
        );
        await this.$store.dispatch("updatePreRequisiteOnServer", {
          userType: this.$userType,
          userId: this.$userId,
          brokerId: this.$brokerId,
          num: 8
        });
      }
      // this.getBrokerStaffWithRoles();
    } catch (error) {
      console.log(error, "saveProcessorAgainstRole");
    }
  }
  /**
   * To check if any role doesnot have any processor
   */
  public async validate() {
    try {
      this.validation = this.roles.filter(r => {
        return this.brokerStaff.find(bStaff =>
          bStaff.roles.find(role => role.id == r.id && role.checked)
        )
          ? false
          : true;
      });
      this.errorMessage = "No processor have been assigned to\n";
      this.validation.forEach(e => {
        this.errorMessage += e.name + "\n";
      });
      this.roles.map(e => {
        e["notSelected"] = this.validation.find(role => role.id == e.id)
          ? true
          : false;
        return e;
      });
      this.brokerStaff.forEach(ele => {
        ele.roles.map(e => {
          e["notSelected"] = this.validation.find(role => role.id == e.id)
            ? true
            : false;
          return e;
        });
      });
    } catch (error) {
      console.log(error, "validate");
    }
  }

  /**
   * Fetching Details of PROCESSOR addedBy Broker with all roles
   */
  public async getBrokerStaffWithRoles() {
    try {
      this.loader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/getRolesForBroker",
        { userId: this.$userId });
      this.brokerStaff = response.data;
      this.loader = false;
    } catch (error) {
      this.loader = false;

      console.log(error, "getBrokerStaffWithRoles");
    }
  }

  public async goPrevious() {
    this.previousInProcess = true;
    await this.modifyPreRequisite(this.completedPreRequisite - 1);
    this.previousInProcess = false;
  }
  get completedPreRequisite() {
    return this.$store.state.sessionObject.userInfo.completedPreRequisites;
  }
  /**
   * To get the names of Role for table header
   */
  public async getRolesName() {
    try {
      let response = await Axios.get(BASE_API_URL + "superAdmin/getRoles");

      this.roles = response.data.roles.filter(e => e.id != 8 && e.id != 9);
      this.taskRole = response.data.roles.filter(
        e => e.id == 7 || e.id == 8 || e.id == 9
      );
    } catch (error) {
      console.log(error, "getRolesName");
    }
  }
  async mounted() {
    await this.getBrokerStaffWithRoles();
    this.getRolesName();
  }
}
